export function humanSize(bytes: number) {
    if (bytes > 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    if (bytes > 1024) return (bytes / 1024).toFixed(2) + " kB";
    return bytes + " B";
}

export function humanArea(sqMeters: number) {
    if (sqMeters > 1_000_000) return (sqMeters / 1_000_000).toFixed(1) + " km²";
    if (sqMeters > 10_000) return (sqMeters / 10_000).toFixed(1) + " ha";
    return sqMeters.toFixed(1) + " m²";
}

export function humanDuration(durationMs: number) {
    if (durationMs > 1_000) return (durationMs / 1_000).toFixed(2) + " s";
    return durationMs.toFixed(2) + " ms";
}

export const COLOR_RAMP = [
    [166, 206, 227],
    [31, 120, 180],
    [178, 223, 138],
    [51, 160, 44],
    [251, 154, 153],
    [227, 26, 28],
    [253, 191, 111],
    [255, 127, 0],
    [202, 178, 214],
    [106, 61, 154],
    [255, 255, 153],
    [177, 89, 40],
];

// COLOR_RAMP.sort(() => Math.random() - 0.5);

export function getRampedColor(idx: number) {
    return COLOR_RAMP[idx % COLOR_RAMP.length];
}

export type FileMetadataItem = {
    num_points: number;
    offsets: [number, number, number];
    original_filename: string;
    license?: string;
    file_size: number;
    year: number;
    scales: [number, number, number];
    transformed_aabb: {
        max: [number, number, number];
        min: [number, number, number];
    };
};

export async function fetchMetadata(tableName: string) {
    const response: {
        projection: string;
        file_metadata: Record<string, FileMetadataItem>;
    } = await fetch(`/api/tables/${tableName}/table_metadata`).then((r) => r.json());

    return {
        projection: response.projection,
        files: new Map<number, FileMetadataItem>(
            Object.entries(response.file_metadata).map(([key, value]) => [Number(key), value]),
        ),
    };
}
